import Card from '@shoreag/base/Card';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import get from 'lodash/get';
import parseUuid from '@shoreag/helpers/parse-uuid';
import { AuthContext } from '@shoreag/auth';
import { Field, Form as FinalForm } from 'react-final-form';
import Box from '@shoreag/base/Box';
import Input from '@shoreag/base/Input';
import { required } from '@shoreag/validations';
import TextArea from '@shoreag/base/TextArea';
import arrayMutators from 'final-form-arrays';
import FileUpload from '@shoreag/base/FileUpload';
import NotFoundPage from '../../routes/default/404';
import Route from '../Route';
import isPermitted from '../../utilities/is-permitted';
import {
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
  TEMPLATES_LIST,
} from '../../utilities/constants';
import SchemaDropdown from '../SchemaDropdown';
import FormError from '../FormError';
import ActionBar from '../ActionBar';
import TagsFormSection from '../TagsFormSection';
import config from '../../config';
import stripSpecialCharacters from '../../utilities/strip-special-characters';
import TemplateMappings from '../TemplateMappings';
import localStorage from '../../utilities/local-storage';
// import createTemplateMutation from "../../graphql/mutations/create-template.gql";

const TemplateCreatePage = ({ schemaIdWithVersion, navigate }) => {
  const { user } = useContext(AuthContext);
  const { id, version } = parseUuid(schemaIdWithVersion);
  const allTemplates = localStorage.getItem(TEMPLATES_LIST)
    ? JSON.parse(localStorage.getItem(TEMPLATES_LIST))
    : [];
  const selectedTemplate =
    allTemplates.find(
      (template) => template.schemaVersionId === schemaIdWithVersion
    ) || null;
  const fileList = [];
  const initialValue = selectedTemplate || {};

  if (
    !isPermitted({
      desiredAction: PERMISSION_ACTIONS.W,
      desiredResource: PERMISSION_RESOURCES.TRADING_PARTNERS,
      ...user,
    })
  ) {
    return <NotFoundPage />;
  }

  return (
    <Route
      header={{
        icon: 'template',
        title: `${id ? 'Update' : 'Create'} Template`,
        type: 'Template',
      }}
      isPrivate
    >
      <AuthContext.Consumer>
        {({ user }) => {
          console.log('user', user);
          return (
            <FinalForm
              initialValues={{ ...initialValue }}
              mutators={arrayMutators}
              onSubmit={(values) => {
                const newValues = {
                  ...values,
                  schemaVersionId:
                    values.schemaId && values.versionId
                      ? `${values.schemaId}:${values.versionId}`
                      : null,
                };
                let updatedValues = [...allTemplates];
                const isAlreadyExists = allTemplates.find(
                  (item) => item.schemaVersionId === newValues.schemaVersionId
                );
                if (isAlreadyExists) {
                  updatedValues = allTemplates.map((template) => {
                    if (
                      template.schemaVersionId === newValues.schemaVersionId
                    ) {
                      return {
                        ...template,
                        ...newValues,
                      };
                    }
                    return template;
                  });
                } else {
                  updatedValues.push(newValues);
                }
                localStorage.setItem(
                  TEMPLATES_LIST,
                  JSON.stringify(updatedValues)
                );
                navigate(`/templates/`);
              }}
              render={(formContext) => {
                const { values } = formContext;
                const { mappings, s3Urls, schemaId, versionId } = values;
                const isSchemaId = id || schemaId;
                const isSchemaVersionId = version || versionId;
                let bucketName = null;
                let keyName = null;
                if (s3Urls && s3Urls.length > 0) {
                  const { s3BucketName, s3KeyName } = s3Urls[0];
                  bucketName = s3BucketName;
                  keyName = s3KeyName;
                }
                return (
                  <form onSubmit={formContext.handleSubmit}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: [
                          'maxWidths.form',
                          null,
                          'maxWidths.largeForm',
                          'maxWidths.content',
                        ],
                        mx: 'auto',
                      }}
                    >
                      <Card
                        sx={{
                          mx: 'auto',
                          width: !schemaIdWithVersion ? '100%' : '50%',
                        }}
                      >
                        <Box display={{ lg: 'flex' }} justifyContent="center">
                          {!schemaIdWithVersion && (
                            <Box data-cy="files" flex="1">
                              <Field
                                acceptedFileTypes={['application/pdf']}
                                allowMultiple
                                allowRevert
                                bucket={config.buckets.templates}
                                component={FileUpload}
                                customPrefix={{ public: '' }}
                                fileRenameFunction={({
                                  basename,
                                  extension,
                                }) => {
                                  const timestamp = new Date().getTime();

                                  const baseStripped = stripSpecialCharacters(
                                    basename
                                  );

                                  const filename = `${baseStripped}${extension}`;

                                  // const clientId = getClientIdFromCognitoGroups(
                                  //   user.cognitoGroups
                                  // );
                                  return `data/${timestamp}/${filename}`;
                                }}
                                labelIdle={`
                                    <div class="filepond--heading">
                                      Drag & drop files here
                                    </div>
                                    <div class="filepond--or">
                                      or
                                    </div>
                                    <div
                                      class="filepond--label-action"
                                    >
                                      Add Files
                                    </div>
                                    <div class="filepond--supported">
                                      Supported file formats: pdf only.
                                    </div>
                                  `}
                                name="s3Urls"
                                onRemoveComplete={({ name }) => {
                                  fileList.splice(
                                    fileList.findIndex(
                                      (f) => f.s3KeyName === name
                                    ),
                                    1
                                  );
                                  return formContext.form.change('s3Urls', [
                                    ...get(
                                      formContext,
                                      'values.s3Urls',
                                      []
                                    ).filter((f) => f.s3KeyName !== name),
                                  ]);
                                }}
                                onUploadComplete={({ fileName }) => {
                                  fileList.push({
                                    s3BucketName: config.buckets.templates,
                                    s3KeyName: `${fileName}`,
                                  });
                                  return formContext.form.change(
                                    's3Urls',
                                    fileList
                                  );
                                }}
                                validate={required}
                                wrapperSx={{
                                  '.filepond--drop-label': {
                                    height: 'auto',
                                    py: [5, null, null, null, 8],
                                  },
                                  '.filepond--heading': {
                                    color: 'text.primary',
                                    fontSize: 5,
                                    fontWeight: 'bold',
                                    lineHeight: '1em',
                                  },
                                  '.filepond--label-action': (p) => ({
                                    mt: 5,
                                    textDecoration: 'none',
                                    ...p.variants.buttons.primary,
                                  }),
                                  '.filepond--or': {
                                    color: 'text.primary',
                                    fontSize: 2,
                                    fontWeight: 'bold',
                                    mt: 5,
                                    textTransform: 'uppercase',
                                  },
                                  '.filepond--supported': {
                                    fontSize: 2,
                                    mt: 5,
                                  },
                                  mb: 6,
                                  mr: [null, null, null, null, 6],
                                }}
                              />
                            </Box>
                          )}
                          <Box
                            sx={{
                              ml: 'auto',
                              width: !schemaIdWithVersion ? '50%' : '100%',
                            }}
                          >
                            <Field
                              component={Input}
                              data-cy="templateName"
                              label="Template Name *"
                              name="templateName"
                              validate={required}
                            />
                            <Field
                              component={TextArea}
                              data-cy="description"
                              label="Description *"
                              name="description"
                              validate={required}
                            />
                            <SchemaDropdown
                              disabled={!!schemaIdWithVersion}
                              formContext={formContext}
                              isRequired
                              schemaId={id}
                            />
                            <Box sx={{ color: 'text.subtle', mt: 6 }}>Tags</Box>
                            <TagsFormSection mt={4} />
                            <FormError>{formContext.submitError}</FormError>
                          </Box>
                        </Box>
                      </Card>
                      <TemplateMappings
                        bucketName={bucketName}
                        formContext={formContext}
                        keyName={keyName}
                        // onMappingUpdate={getMappings}
                        mappings={mappings}
                        schemaId={isSchemaId}
                        schemaVersionId={isSchemaVersionId}
                      />
                      <ActionBar
                        isFixed
                        leftButtonProps={{
                          children: 'Cancel',
                          onClick: () => navigate('/templates/'),
                        }}
                        rightButtonProps={{
                          children: 'Save Template',
                          'data-cy': 'save',
                          submitting: formContext.submitting,
                          sx: {
                            ml: 5,
                          },
                          type: 'submit',
                        }}
                      />
                    </Box>
                  </form>
                );
              }}
            />
          );
        }}
      </AuthContext.Consumer>
    </Route>
  );
};

TemplateCreatePage.propTypes = {
  navigate: PropTypes.func.isRequired,
  schemaIdWithVersion: PropTypes.string,
};

TemplateCreatePage.defaultProps = {
  schemaIdWithVersion: null,
};

export default TemplateCreatePage;
