import React from 'react';
import { Router } from '@reach/router';
import NotFoundPage from './404';
import TemplateCreatePage from '../../components/TemplateCreatePage';
import TemplateDashboardPage from '../../components/TemplateDashboardPage';

const PartnersRouter = (props) => (
  <Router>
    <TemplateCreatePage {...props} path="/templates/:schemaIdWithVersion" />
    <TemplateCreatePage {...props} path="/templates/create" />
    <TemplateDashboardPage {...props} path="/templates/" />
    <NotFoundPage default />
  </Router>
);

export default PartnersRouter;
