import Box from '@shoreag/base/Box';
import Button from '@shoreag/base/Button';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Card from '@shoreag/base/Card';
import Icon from '../Icon';

const Sidebar = ({
  disableToggle,
  buttonSx,
  children,
  isOpen,
  title,
  titleSx,
  wrapperSx,
  bodySx,
  customToggle,
  ...rest
}) => {
  const [toggled, setToggled] = useState(isOpen);
  const hideToggle = disableToggle || customToggle;

  return (
    <Card
      sx={{
        maxWidth: 'maxWidths.form',
        overflow: 'hidden',
        p: 0,
        textAlign: 'center',
        ...wrapperSx,
      }}
      {...rest}
    >
      <Button
        onClick={() => (hideToggle ? null : setToggled(!toggled))}
        simple
        sx={{
          alignItems: 'center',
          bg: 'primary',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          p: 'md',
          textDecoration: 'none',
          width: '100%',
          ...buttonSx,
        }}
      >
        <Box
          as="p"
          sx={{
            color: 'inherit',
            fontSize: 4,
            fontWeight: 'bold',
            ...titleSx,
          }}
        >
          {title}
        </Box>
        {!hideToggle && (
          <Box
            alignItems="center"
            bg="white"
            display="flex"
            height="1.4rem"
            justifyContent="center"
            ml="sm"
            sx={{
              borderRadius: '50%',
              flexShrink: 0,
              transform: toggled ? null : 'rotate(180deg)',
            }}
            width="1.4rem"
          >
            <Icon
              color="primary"
              height="1rem"
              mt="-0.5rem"
              svg="chevron"
              width="1rem"
            />
          </Box>
        )}
        {customToggle}
      </Button>
      {toggled && <Box sx={{ px: 'md', py: 'sm', ...bodySx }}>{children}</Box>}
    </Card>
  );
};

Sidebar.propTypes = {
  bodySx: PropTypes.shape({}),
  buttonSx: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  customToggle: PropTypes.node,
  disableToggle: PropTypes.bool,
  isOpen: PropTypes.bool,
  title: PropTypes.node.isRequired,
  titleSx: PropTypes.shape({}),
  wrapperSx: PropTypes.shape({}),
};

Sidebar.defaultProps = {
  bodySx: {},
  buttonSx: {},
  customToggle: null,
  disableToggle: false,
  isOpen: true,
  titleSx: {},
  wrapperSx: {},
};

export default Sidebar;
