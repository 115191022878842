import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import Box from '@shoreag/base/Box';
import Paginator from '@shoreag/base/Paginator';
import { Field, Form } from 'react-final-form';
import Dropdown from '@shoreag/base/Dropdown';
import noop from 'lodash/noop';
import ToggleSwitch from '@shoreag/base/ToggleSwitch';
import { flatten } from 'lodash';
import Pdf from '../PdfViewer/pdf';
import Icon from '../Icon';

const PdfMap = ({ file, sx, mappings, fieldOptions, onMapUpdate }) => {
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedForEdit, setSelectedForEdit] = useState(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [areaMarkings, setAreaMarkings] = useState({ ...mappings });
  const [isPdf, setIsPdf] = useState(false);
  const [ghostArea, setGhostArea] = useState({
    area: {
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 0,
    },
    map: {
      height: 0,
      left: 0,
      top: 0,
      width: 0,
    },
  });

  useEffect(() => {
    const mappedFields = flatten([
      ...Object.keys(areaMarkings).map((page) =>
        areaMarkings[page].map((i) => i.label)
      ),
    ]);
    setSelectedFields(mappedFields);
    onMapUpdate({
      fieldNames: mappedFields,
      mappings: {
        ...areaMarkings,
      },
    });
  }, [areaMarkings]);

  const pdfDisplayAreaRef = useRef();

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const onDocumentLoadSuccess = (values) => {
    const { numPages } = values;
    setTotalPages(numPages);
    setIsPdf(true);
  };

  const getPdfAreaPageXY = () => {
    const pdfArea = pdfDisplayAreaRef.current.getBoundingClientRect();
    return {
      PdfAreaLeft: pdfArea.left,
      PdfAreaTop: pdfArea.top,
    };
  };

  const onMouseDown = (e) => {
    setIsMouseDown(true);
    const { PdfAreaLeft, PdfAreaTop } = getPdfAreaPageXY();
    const pageX = e.clientX - PdfAreaLeft;
    const pageY = e.clientY - PdfAreaTop;
    const { area, map } = ghostArea;
    setGhostArea({
      ...ghostArea,
      area: {
        ...area,
        x1: pageX,
        y1: pageY,
      },
      map: {
        ...map,
        display: 'block',
        left: pageX,
        top: pageY,
      },
    });
  };

  const onMouseMove = (e) => {
    if (isMouseDown) {
      const { PdfAreaLeft, PdfAreaTop } = getPdfAreaPageXY();
      const initialW = ghostArea.map.left;
      const initialH = ghostArea.map.top;
      const pageX = e.clientX - PdfAreaLeft;
      const pageY = e.clientY - PdfAreaTop;
      const w = Math.abs(initialW - pageX);
      const h = Math.abs(initialH - pageY);
      const { area, map } = ghostArea;
      setGhostArea({
        ...ghostArea,
        area: {
          ...area,
          x2: pageX,
          y2: pageY,
        },
        map: {
          ...map,
          height: h,
          width: w,
        },
      });
      if (pageX <= initialW && pageY >= initialH) {
        setGhostArea({
          ...ghostArea,
          area: {
            ...area,
            x1: pageX,
          },
          map: {
            ...map,
            left: pageX,
          },
        });
      } else if (pageY <= initialH && pageX >= initialW) {
        setGhostArea({
          ...ghostArea,
          area: {
            ...area,
            y1: pageY,
          },
          map: {
            ...map,
            top: pageY,
          },
        });
      } else if (pageY < initialH && pageX < initialW) {
        setGhostArea({
          ...ghostArea,
          area: {
            ...area,
            x1: pageX,
            y1: pageY,
          },
          map: {
            ...map,
            left: pageX,
            top: pageY,
          },
        });
      }
    }
  };

  const onMouseUp = () => {
    setIsMouseDown(false);
    setGhostArea({
      area: {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 0,
      },
      map: {
        display: 'none',
        height: 0,
        left: 0,
        top: 0,
        width: 0,
      },
    });
    if (ghostArea.map.height > 15 && ghostArea.map.width > 15) {
      handleAdd();
    }
  };

  const handleAdd = () => {
    const currentPageMarkings = areaMarkings[currentPage] || [];
    const mapId = `pageNo:${currentPage}-mapNo:${Math.random()
      .toString(36)
      .substr(2, 9)}`;
    const updatedMarking = {
      ...areaMarkings,
      [currentPage]: [
        ...currentPageMarkings,
        {
          ...ghostArea,
          label: '',
          mapId,
        },
      ],
    };
    // setSelectedForEdit(mapId);
    setAreaMarkings(updatedMarking);
  };

  const handleDelete = (item) => {
    const currentPageMarkings = areaMarkings[currentPage] || [];
    const newAreaMarkings = currentPageMarkings.filter(
      (i) => i.mapId !== item.mapId
    );
    const updatedMarking = {
      ...areaMarkings,
      [currentPage]: [...newAreaMarkings],
    };
    setAreaMarkings(updatedMarking);
    // setSelectedFieldsList(newAreaMarkings, item.label);
    setSelectedForEdit(null);
  };

  const UpdateMapValues = ({ label, isSelection }) => {
    const { datumDefinitionId } =
      fieldOptions.find((i) => i.fieldName === label) || {};
    const currentPageMarkings = [...areaMarkings[currentPage]];
    const selectedMarkings = currentPageMarkings.map((i) => {
      return i.mapId === selectedForEdit.mapId
        ? {
            ...i,
            datumDefinitionId,
            isSelection,
            label,
          }
        : i;
    });
    const updatedMarking = {
      ...areaMarkings,
      [currentPage]: [...selectedMarkings],
    };
    setAreaMarkings(updatedMarking);
    // setSelectedFieldsList(selectedMarkings);
    setSelectedForEdit(null);
  };

  // const setSelectedFieldsList = (list, removeItem) => {
  //   const selectedOptions = [
  //     ...selectedFields.filter((i) => i !== removeItem),
  //     ...list.map((item) => item.label && item.label),
  //   ];
  //   setSelectedFields(selectedOptions);
  //   onMapUpdate({
  //     fieldNames: [...selectedOptions],
  //     mappings: {
  //       ...areaMarkings,
  //     },
  //   });
  // };

  return (
    <>
      <Box sx={{ position: 'relative', ...sx }}>
        <Pdf
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          pageNumber={currentPage}
        />
        {isPdf && fieldOptions.length > 0 && (
          <>
            <Box
              ref={pdfDisplayAreaRef}
              onMouseDown={(event) => onMouseDown(event)}
              onMouseMove={(event) => onMouseMove(event)}
              onMouseUp={() => onMouseUp()}
              sx={{
                bg: selectedForEdit ? 'rgba(0, 0, 0, .2)' : '',
                bottom: 0,
                left: 0,
                pointerEvents: selectedForEdit ? 'none' : '',
                position: 'absolute',
                right: 0,
                top: 0,
              }}
            >
              <Box
                sx={{
                  border: '2px dotted',
                  color: 'black',
                  display: 'none',
                  // opacity: '0.5',
                  position: 'absolute',
                  zIndex: 2,
                  ...ghostArea.map,
                }}
              />
            </Box>
            {areaMarkings[currentPage] &&
              areaMarkings[currentPage].length > 0 &&
              areaMarkings[currentPage].map((mark, index) => {
                const noneSelected = !selectedForEdit;
                const isSelected =
                  selectedForEdit && mark.mapId === selectedForEdit.mapId;
                return (
                  <Box
                    key={`${mark}-${index}`}
                    sx={{
                      '&:hover': {
                        zIndex: 2,
                      },
                      bg: 'accentTransparent',
                      border: '1px dashed',
                      borderColor: 'primary',
                      display: 'flex',
                      flexDirection: 'column',
                      opacity: noneSelected || isSelected ? '' : '.5',
                      pointerEvents: noneSelected || isSelected ? '' : 'none',
                      position: 'absolute',
                      zIndex: 1,
                      ...mark.map,
                    }}
                  >
                    <Box
                      sx={{
                        '&:hover > footer': {
                          display: 'flex',
                        },
                        color: 'white',
                        flexDirection: 'column',
                        fontSize: 1,
                        height: '100%',
                        position: 'relative',
                        width: '100%',
                      }}
                    >
                      <Box
                        onClick={() => setSelectedForEdit(mark)}
                        sx={{ height: '100%', pl: 1, pt: 1, width: '100%' }}
                      >
                        {mark.label || 'add Label'}
                      </Box>

                      <Box
                        as="footer"
                        sx={{
                          display: isSelected ? 'flex' : 'none',
                          position: 'absolute',
                          right: 0,
                          top: 0,
                        }}
                      >
                        {selectedForEdit ? (
                          <Box
                            onClick={() => setSelectedForEdit(null)}
                            sx={{
                              bg: 'primary',
                              color: 'white',
                              height: '1.4rem',
                              px: 2,
                              py: 1,
                            }}
                          >
                            Cancel
                          </Box>
                        ) : (
                          <Icon
                            onClick={() => setSelectedForEdit(mark)}
                            svg="edit"
                            sx={{
                              bg: 'primary',
                              color: 'white',
                              height: '1.4rem',
                              p: 1,
                              width: '1.4rem',
                            }}
                            title="Edit"
                          />
                        )}
                        <Icon
                          onClick={() => handleDelete(mark)}
                          svg="trash"
                          sx={{
                            bg: 'white',
                            color: 'error',
                            height: '1.4rem',
                            p: 1,
                            width: '1.4rem',
                          }}
                          title="Delete"
                        />
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            {selectedForEdit && (
              <Box
                sx={{
                  bg: 'white',
                  borderRadius: 3,
                  left: selectedForEdit.map.left,
                  pb: 6,
                  position: 'absolute',
                  pt: 4,
                  px: 4,
                  top: selectedForEdit.map.top + selectedForEdit.map.height,
                  zIndex: 3,
                }}
              >
                <Form
                  initialValues={{
                    fieldName: selectedForEdit.label,
                    isSelection: selectedForEdit.isSelection,
                  }}
                  onSubmit={noop}
                  render={(formValues) => {
                    const isSelectionChanged =
                      selectedForEdit.isSelection !==
                      formValues.values.isSelection;
                    if (isSelectionChanged) {
                      // console.log('selectedForEdit', selectedForEdit);
                      UpdateMapValues({
                        isSelection: formValues.values.isSelection,
                        label: selectedForEdit.label,
                      });
                    }
                    return (
                      <>
                        <Field
                          component={ToggleSwitch}
                          errorSx={{
                            ml: 4,
                            mt: 0,
                          }}
                          label="Is Selection?"
                          labelSx={{
                            ml: 0,
                            mr: 6,
                            order: 'unset',
                          }}
                          name="isSelection"
                          type="checkbox"
                          wrapperSx={{
                            alignItems: 'center',
                            display: 'flex',
                          }}
                        />
                        <Field
                          autoFocus
                          component={Dropdown}
                          label="Field Name"
                          name="fieldName"
                          onChange={(value) =>
                            UpdateMapValues({
                              isSelection: formValues.values.isSelection,
                              label: value,
                            })
                          }
                          options={fieldOptions
                            .filter(
                              (item) =>
                                item.fieldName === selectedForEdit.label ||
                                !selectedFields.includes(item.fieldName)
                            )
                            .map((field) => ({
                              label: `${field.fieldName} ${
                                field.isMandatory ? '*' : ''
                              }`,
                              value: field.fieldName,
                            }))}
                          wrapperSx={{
                            minWidth: '250px',
                          }}
                        />
                      </>
                    );
                  }}
                />
              </Box>
            )}
          </>
        )}
      </Box>
      {isPdf && (
        <Paginator
          leftButtonContent={
            <Icon height="0.75rem" svg="left-chevron" width="0.5rem" />
          }
          offset={currentPage - 1}
          onNextClick={() => nextPage()}
          onPreviousClick={() => prevPage()}
          pageSize={1}
          pageTotal={1}
          rightButtonContent={
            <Icon height="0.75rem" svg="right-chevron" width="0.5rem" />
          }
          total={totalPages}
        />
      )}
    </>
  );
};

PdfMap.propTypes = {
  fieldOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  file: PropTypes.string.isRequired,
  mappings: PropTypes.shape({}),
  onMapUpdate: PropTypes.func,
  sx: PropTypes.shape({}),
};

PdfMap.defaultProps = {
  mappings: {},
  onMapUpdate: () => {},
  sx: {},
};

export default PdfMap;
