import Card from '@shoreag/base/Card';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import Spinner from '@shoreag/base/Spinner';
import get from 'lodash/get';
import { Query } from 'react-apollo';
import Box from '@shoreag/base/Box';
import { ThemeContext } from 'styled-components';
import { Storage } from 'aws-amplify';
import schemaQuery from '../../graphql/queries/schema.gql';
import PdfMap from '../PdfMap';
import Sidebar from '../Sidebar';
import Icon from '../Icon';

const TemplateMappings = ({
  bucketName,
  keyName,
  schemaId,
  schemaVersionId,
  mappings,
  formContext,
}) => {
  const theme = useContext(ThemeContext);
  const [selectedFields, setSelectedFields] = useState([]);
  const [fileBucketName, setFileBucketName] = useState(null);
  const [fileKeyName, setFileKeyName] = useState(null);
  const [filePath, setFilePath] = useState(null);

  const getS3File = async (s3KeyName, s3BucketName) => {
    Storage.configure({ AWSS3: { bucket: s3BucketName } });
    return new Promise((resolve, reject) => {
      Storage.get(s3KeyName, { expires: 60 })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const getFilePath = async () => {
    const isBucketNameChanged = bucketName !== fileBucketName;
    const isKeyNameChanged = keyName !== fileKeyName;
    if (isBucketNameChanged || isKeyNameChanged) {
      const path = await getS3File(keyName, bucketName);
      setFilePath(path);
      setFileBucketName(bucketName);
      setFileKeyName(keyName);
    }
  };

  const setSideBar = (fields, title) => {
    const selected = fields
      .map((field) => selectedFields.includes(field.fieldName))
      .filter((i) => i).length;
    const total = fields.length;
    return (
      <Sidebar
        buttonSx={{
          position: 'relative',
        }}
        title={
          <>
            {title}
            <Box
              as="span"
              sx={{
                bg: selected === total ? 'success' : 'error',
                borderRadius: 3,
                color: 'white',
                fontSize: 1,
                ml: 2,
                px: 3,
                py: 1,
              }}
            >
              {/* {total - selected} */}
              {`${selected} / ${total}`}
            </Box>
          </>
        }
        titleSx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Box
          sx={{
            maxHeight: '30rem',
            mr: -5,
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          {fields.map((i, index) => {
            const isSelected = selectedFields.includes(i.fieldName);
            return (
              <Box
                key={`${i.fieldName}-${index}`}
                sx={{
                  display: 'flex',
                  fontSize: 2,
                  my: 3,
                  textAlign: 'left',
                }}
                title={i.fieldName}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    bg: isSelected ? 'success' : 'grays.2',
                    borderRadius: '50%',
                    color: 'white',
                    display: 'flex',
                    flex: 'none',
                    height: '1.4rem',
                    justifyContent: 'center',
                    mr: 3,
                    width: '1.4rem',
                  }}
                >
                  {isSelected && (
                    <Icon
                      svg="check"
                      sx={{
                        height: '1rem',
                        width: '1rem',
                      }}
                    />
                  )}
                </Box>
                <Box>{i.fieldName}</Box>
              </Box>
            );
          })}
        </Box>
      </Sidebar>
    );
  };

  const handleMappingUpdate = (value) => {
    const { fieldNames, mappings } = value;
    formContext.form.change('mappings', mappings);
    setSelectedFields(fieldNames || []);
  };

  return (
    <Query
      fetchPolicy="network-only"
      query={schemaQuery}
      variables={{ id: schemaId }}
    >
      {({ data, loading }) => {
        let isPathSet = null;
        if (bucketName && keyName) {
          isPathSet = getFilePath();
        } else {
          setFilePath(null);
          setFileBucketName(bucketName);
          setFileKeyName(keyName);
        }

        const versions = get(data, 'datasetSchema.versions', []);

        const version =
          versions.find((v) => v.versionNumber === String(schemaVersionId)) ||
          versions[0];

        const mandatoryFields = [];
        const optionalFields = [];
        const schemaFields = get(version, 'definitions', []).map((v) => {
          const field = {
            datumDefinitionId: v.id.split('::').shift(),
            fieldName: v.fieldName,
            isMandatory: v.isMandatory,
          };
          if (v.isMandatory) {
            mandatoryFields.push(field);
          } else {
            optionalFields.push(field);
          }
          return field;
        });
        return isPathSet && filePath ? (
          <Box sx={{ display: 'flex' }}>
            <Card
              sx={{
                maxWidth: `calc(${theme.sizes.maxWidths.largeForm} + (${theme.space[4]} * 2))`,
                p: 4,
                width: '100%',
              }}
            >
              <PdfMap
                fieldOptions={schemaFields}
                file={filePath}
                mappings={mappings}
                onMapUpdate={handleMappingUpdate}
              />
            </Card>
            <Box
              sx={{
                flex: 'auto',
                height: '100%',
                ml: 5,
                position: 'sticky',
                right: 0,
                top: theme.sizes.heights.header,
              }}
            >
              {loading ? (
                <Spinner />
              ) : (
                <>
                  {schemaFields.length > 0 ? (
                    <>
                      {mandatoryFields.length > 0 &&
                        setSideBar(mandatoryFields, 'Mandatory')}
                      {optionalFields.length > 0 &&
                        setSideBar(optionalFields, 'Optional')}
                    </>
                  ) : (
                    <Card>No Schema selected.</Card>
                  )}
                </>
              )}
            </Box>
          </Box>
        ) : null;
      }}
    </Query>
  );
};

TemplateMappings.propTypes = {
  bucketName: PropTypes.string,
  formContext: PropTypes.shape({
    form: PropTypes.shape({
      change: PropTypes.func,
    }),
  }).isRequired,
  keyName: PropTypes.string,
  mappings: PropTypes.shape({}),
  schemaId: PropTypes.string,
  schemaVersionId: PropTypes.string,
};

TemplateMappings.defaultProps = {
  bucketName: null,
  keyName: null,
  mappings: {},
  schemaId: '',
  schemaVersionId: '',
};

export default TemplateMappings;
