import PropTypes from 'prop-types';
import React from 'react';
import Box from '@shoreag/base/Box';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import { useResizeDetector } from 'react-resize-detector';
import Spinner from '@shoreag/base/Spinner';

const Pdf = ({ sx, file, pageNumber, onLoadSuccess, width }) => {
  const { width: responsiveWidth, ref } = useResizeDetector();
  return (
    <Box ref={ref} sx={sx}>
      <Document file={file} loading={<Spinner />} onLoadSuccess={onLoadSuccess}>
        <Page pageNumber={pageNumber} width={width || responsiveWidth} />
      </Document>
    </Box>
  );
};

Pdf.propTypes = {
  file: PropTypes.string.isRequired,
  onLoadSuccess: PropTypes.func,
  pageNumber: PropTypes.number,
  sx: PropTypes.shape({}),
  width: PropTypes.number,
};

Pdf.defaultProps = {
  onLoadSuccess: () => {},
  pageNumber: 1,
  sx: {},
  width: null,
};

export default Pdf;
