import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import Spinner from '@shoreag/base/Spinner';
import { useQuery } from 'react-apollo';
import allSchemaOptimizedQuery from '../../graphql/queries/all-schema-optimized.gql';
import parseUuid from '../../utilities/parse-uuid';
import { REGEXES, UUID_PREFIXES } from '../../utilities/constants';
import LinkWithVersion from '../LinkWithVersion';

const { UUID } = REGEXES;
const { SCHEMA } = UUID_PREFIXES;

const DisplaySchemaData = ({ schemaId, fetchPolicy }) => {
  const { id, version: versionId } = parseUuid(schemaId);
  const isValidSchemaId = new RegExp(`^${SCHEMA}${UUID}$`).test(id);
  if (isValidSchemaId) {
    const { data, loading } = useQuery(allSchemaOptimizedQuery, {
      fetchPolicy,
      skip: !id,
      variables: { ids: [id], optimized: true },
    });
    const schemaData = get(data, 'allSchema[0]', {});

    if (loading) {
      return (
        <Spinner
          justifyContent="left"
          py={1}
          sx={{
            transform: 'scale(0.5)',
            transformOrigin: '0',
          }}
          width="100px"
        />
      );
    }

    return data ? (
      <LinkWithVersion
        link={
          schemaData.id
            ? `/schemas/${schemaData.id}:${versionId}`
            : `/schemas/${schemaId}`
        }
        name={schemaData.name || schemaId}
        version={versionId}
      />
    ) : (
      '-'
    );
  }
  return schemaId;
};

DisplaySchemaData.defaultProps = {
  fetchPolicy: 'cache-first',
  schemaId: null,
};

DisplaySchemaData.propTypes = {
  fetchPolicy: PropTypes.string,
  schemaId: PropTypes.string,
};

export default DisplaySchemaData;
