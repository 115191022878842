import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import DropdownMenu from '@shoreag/base/DropdownMenu';
import React, { useContext, useState } from 'react';
import Table from '@shoreag/base/Table';
import { AuthContext } from '@shoreag/auth';
import { TooltipBox } from '@shoreag/base/Tooltip';
import ToolbarButton from '../ToolbarButton';
import DotsIcon from '../DotsIcon';
import Route from '../Route';
import renderDropdownMenuItem from '../../utilities/render-dropdown-menu-item';
import isPermitted from '../../utilities/is-permitted';
import {
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
  TEMPLATES_LIST,
} from '../../utilities/constants';
import LinkWithVersion from '../LinkWithVersion';
import localStorage from '../../utilities/local-storage';
import DisplaySchemaData from '../DisplaySchemaData';
import Tags from '../Tags';
import TagTooltipBox from '../TagTooltip/TagTooltipBox';

const TemplateDashboardPage = () => {
  const { user } = useContext(AuthContext);

  const hasWritePermission = isPermitted({
    desiredAction: PERMISSION_ACTIONS.W,
    desiredResource: PERMISSION_RESOURCES.TRADING_PARTNERS,
    ...user,
  });
  const allTemplates = localStorage.getItem(TEMPLATES_LIST)
    ? JSON.parse(localStorage.getItem(TEMPLATES_LIST))
    : [];

  const [templateList, setTemplateList] = useState(allTemplates);

  const deleteTemplate = (templateId) => {
    const updatedValues = allTemplates.filter(
      (template) => template.schemaVersionId !== templateId
    );
    localStorage.setItem(TEMPLATES_LIST, JSON.stringify(updatedValues));
    setTemplateList(updatedValues);
  };

  return (
    <Route
      header={{
        icon: 'template',
        rightContainer: hasWritePermission && (
          <ToolbarButton
            icon="plus"
            label="Create Template"
            link="/templates/create"
          />
        ),
        title: 'Dashboard',
        type: 'Template',
      }}
      isPrivate
    >
      <Card p={0}>
        <Table
          header={['Template Name', 'Description', 'Tags', 'Schema', '']}
          // isLoading={loading}
          rows={templateList.map((template) => {
            const dropdownItems = [['view', 'View / Edit']];
            const dropdownItemActions = [
              `/templates/${template.schemaVersionId}`,
            ];
            dropdownItems.push(['trash', 'Delete']);
            dropdownItemActions.push(() =>
              deleteTemplate(template.schemaVersionId)
            );

            return [
              <LinkWithVersion
                ellipsis={false}
                link={`/templates/${template.schemaVersionId}`}
                name={template.templateName}
              />,
              template.description,
              <Tags tags={template.tags} />,
              template.schemaVersionId ? (
                <DisplaySchemaData schemaId={template.schemaVersionId} />
              ) : (
                '-'
              ),
              <Box sx={{ float: 'right' }}>
                <DropdownMenu
                  button={<DotsIcon />}
                  itemActions={[...dropdownItemActions]}
                  items={[...dropdownItems].map(renderDropdownMenuItem)}
                />
              </Box>,
            ];
          })}
        />
        <TooltipBox id="tooltip" />
        <TagTooltipBox />
      </Card>
    </Route>
  );
};

export default TemplateDashboardPage;
